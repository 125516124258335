var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('label',[_vm._v(" "+_vm._s(_vm.sliderTitle)+" ")]),_c('ejs-slider',{staticStyle:{"width":"300px","min-height":"45px","padding-top":"16px"},attrs:{"value":_vm.sliderValue,"tooltip":{ showOn: 'Hover', isVisible: true },"min":_vm.minParameterProps.min,"max":_vm.minParameterProps.max,"step":_vm.minParameterProps.step ? _vm.minParameterProps.step: undefined,"ticks":{
      placement: 'Before',
      largeStep: _vm.minParameterProps.rangeSlider.rangeSliderTickInterval ?
        _vm.minParameterProps.rangeSlider.rangeSliderTickInterval :
        1,
      smallStep: 0.5,
      showSmallTicks: true
    },"type":'Range'},model:{value:(_vm.sliderValue),callback:function ($$v) {_vm.sliderValue=$$v},expression:"sliderValue"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }