var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('div',[_c('h2',{directives:[{name:"observe-visibility",rawName:"v-observe-visibility",value:({
        callback: _vm.visibilityChanged,
        intersection: {
          rootMargin: '0px 0px -85% 0px',
          threshold: '.1',
        },
    }),expression:"{\n        callback: visibilityChanged,\n        intersection: {\n          rootMargin: '0px 0px -85% 0px',\n          threshold: '.1',\n        },\n    }"}],staticClass:"md-title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_vm._t("default")],2)])
}
var staticRenderFns = []

export { render, staticRenderFns }